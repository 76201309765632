@layer base {
  :root,
  :host {
    /**
     * @tokens base-color
     * @presenter Color
     */

    --arnge-10: #fff1e9;
    --arnge-30: #ffb992;
    --arnge-50: #fc7022;
    --arnge-70: #d55913;
    --arnge-90: #ae4003;

    --baja-blast-10: #ecf9f3;
    --baja-blast-30: #a0ebc7;
    --baja-blast-50: #43c285;
    --baja-blast-70: #278759;
    --baja-blast-90: #254e3a;

    --baloo-05: #eff3ff;
    --baloo-10: #eaefff;
    --baloo-30: #8fa7fb;
    --baloo-50: #305dfb;
    --baloo-70: #0029bb;
    --baloo-90: #00186d;

    --black-10: rgba(67, 26, 129, 0.02);
    --black-20: rgba(45, 15, 89, 0.05);
    --black-30: rgba(45, 15, 89, 0.1);
    --black-40: rgba(42, 20, 75, 0.15);
    --black-50: rgba(37, 19, 62, 0.25);
    --black-60: rgba(55, 43, 73, 0.5);
    --black-70: rgba(42, 29, 62, 0.7);
    --black-80: rgba(43, 35, 54, 0.8);
    --black-90: rgba(38, 30, 49, 0.9);
    --black-100: rgba(10, 2, 22, 1);

    --fyre-10: #feecee;
    --fyre-30: #ff929e;
    --fyre-50: #f54456;
    --fyre-70: #b20718;
    --fyre-90: #71000b;

    --gray-10: #faf9fc;
    --gray-20: #f3f2f7;
    --gray-30: #eae9f0;
    --gray-40: #dedde4;
    --gray-50: #c7c5d0;
    --gray-60: #9b96a4;
    --gray-70: #696273;
    --gray-80: #544e5c;
    --gray-90: #3b3545;

    --purp-10: #f7f3fd;
    --purp-20: #efe7fc;
    --purp-30: #c9aff3;
    --purp-40: #a871ff;
    --purp-50: #7635de;
    --purp-60: #5f10dd;
    --purp-70: #4300ac;
    --purp-80: #330084;
    --purp-90: #29006b;
    --purp-100: #1e004e;

    --rosi-10: #fdf4fa;
    --rosi-30: #ffd1ef;
    --rosi-50: #eb94ce;
    --rosi-70: #9a467d;
    --rosi-90: #530a3a;

    --serena-10: #f6fcee;
    --serena-30: #cdeba6;
    --serena-50: #a5e752;
    --serena-70: #66ac0d;
    --serena-90: #417400;

    --tang-10: #fff6e8;
    --tang-30: #ffd493;
    --tang-50: #ffa216;
    --tang-70: #d78204;
    --tang-90: #a06000;

    --teal-diamond-10: #eef9fe;
    --teal-diamond-30: #bcebff;
    --teal-diamond-50: #51c2f2;
    --teal-diamond-70: #188bbc;
    --teal-diamond-90: #086891;

    --white-10: rgba(255, 255, 255, 0.1);
    --white-20: rgba(255, 255, 255, 0.2);
    --white-30: rgba(255, 255, 255, 0.3);
    --white-40: rgba(255, 255, 255, 0.4);
    --white-50: rgba(255, 255, 255, 0.5);
    --white-60: rgba(255, 255, 255, 0.6);
    --white-70: rgba(255, 255, 255, 0.7);
    --white-80: rgba(255, 255, 255, 0.8);
    --white-90: rgba(255, 255, 255, 0.9);
    --white-100: rgba(255, 255, 255, 1);

    --yella-10: #fffff2;
    --yella-30: #fffcbd;
    --yella-50: #fffa7c;
    --yella-70: #d5cd15;
    --yella-90: #928c00;
  }
}

@layer semantic {
  :root,
  :host {
    /**
     * @tokens semantic-color
     * @presenter Color
     */

    --brand: var(--purp-60);

    --surface-light-0: var(--white-100);
    --surface-light-1: var(--gray-10);
    --surface-light-2: var(--gray-20);
    --surface-light-3: var(--gray-30);
    --surface-light-4: var(--gray-40);
    --surface-light-5: var(--gray-50);

    --surface-light-danger: var(--fyre-10);
    --surface-light-caution: var(--tang-10);
    --surface-light-warning: var(--yella-10);
    --surface-light-safety: var(--baja-blast-10);
    --surface-light-selected: var(--baloo-05);

    --type-light-high-contrast: var(--black-100);
    --type-light-low-contrast: var(--black-70);
    --type-light-disabled: var(--black-60);
    --type-light-danger: var(--fyre-90);
    --type-light-caution: var(--arnge-90);
    --type-light-warning: var(--yella-90);
    --type-light-safety: var(--baja-blast-90);

    --type-light-link: var(--purp-70);
    --type-light-link-hover: var(--purp-80);
    --type-light-link-active: var(--purp-90);

    --border-light: var(--gray-40);
    --border-light-blend: var(--black-40);

    --surface-dark-0: var(--black-100);
    --surface-dark-1: var(--gray-90);
    --surface-dark-2: var(--gray-80);
    --surface-dark-3: var(--gray-70);
    --surface-dark-4: var(--gray-60);

    --surface-dark-danger: var(--fyre-50);
    --surface-dark-caution: var(--tang-50);
    --surface-dark-warning: var(--yella-70);
    --surface-dark-safety: var(--baja-blast-50);

    --type-dark-high-contrast: var(--white-90);
    --type-dark-low-contrast: var(--white-50);
    --type-dark-disabled: var(--white-40);
    --type-dark-body: var(--white-90);

    --border-dark: var(--gray-60);
    --border-dark-blend: var(--black-70);

    --modal-scrim: var(--black-80);
  }
}
