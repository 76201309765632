@layer base {
  :root,
  :host {
    /**
     * @tokens base-size
     * @presenter Spacing
     */

    --base-grid-size: 4px;
  }
}

@layer semantic {
  :root,
  :host {
    /**
     * @tokens spacing
     * @presenter Spacing
     */

    --size-none: 0;
    --size-xxsmall: calc(var(--base-grid-size) / 2);
    --size-xsmall: calc(var(--base-grid-size));
    --size-small: calc(var(--base-grid-size) * 2);
    --size-medium: calc(var(--base-grid-size) * 3);
    --size-large: calc(var(--base-grid-size) * 4);
    --size-xlarge: calc(var(--base-grid-size) * 6);
    --size-xxlarge: calc(var(--base-grid-size) * 10);

    /**
     * @tokens border-width
     * @presenter Spacing
     */

    --border-width: calc(var(--base-grid-size) / 4);
    --border-width-large: calc(var(--border-width) * 2);

    /**
     * @tokens border-radius
     * @presenter BorderRadius
     */

    --border-radius-none: 0;
    --border-radius-xsmall: calc(var(--base-grid-size) / 4);
    --border-radius-small: calc(var(--base-grid-size) / 2);
    --border-radius-medium: var(--base-grid-size);
    --border-radius-large: calc(var(--base-grid-size) * 2);
    --border-radius-xlarge: calc(var(--base-grid-size) * 4);
  }
}
