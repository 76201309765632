:root,
:host {
  /**
   * @tokens z-index
   */
  --above-scroll-z-index: 2;
  --loadable-z-index: 3;
  --header-z-index: 5;
  --account-menu-z-index: 10;
  --menu-z-index: 20;
  --popout-z-index: 30;
  --modal-z-index: 40;
  --composer-z-index: 45;
  --callout-z-index: 50;
  --toast-z-index: 60;
  --super-search-z-index: 100;
}
