@import url('./reset.css') layer(reset);

@import url('./tokens/color.css') layer(tokens);
@import url('./tokens/depth.css') layer(tokens);
@import url('./tokens/size.css') layer(tokens);
@import url('./tokens/transition.css') layer(tokens);
@import url('./tokens/typography.css') layer(tokens);
@import url('./tokens/z-index.css') layer(tokens);

@layer global {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  html,
  body {
    font-family: var(--font-stack);
    font-weight: var(--font-weight-normal);
    font-size: calc(var(--base-grid-size) * 4);
    line-height: calc(var(--base-grid-size) * 6);
    color: var(--type-light-high-contrast);
    background-color: var(--surface-light-0);
    height: 100%;
  }

  input,
  textarea,
  select,
  button {
    font-family: var(--font-stack);
    margin: 0;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}
