:root,
:host {
  /**
   * @tokens depth
   * @presenter Shadow
   */
  --depth-1: 0 2px 2px rgba(10, 2, 22, 0.15);
  --depth-2: 0 6px 6px -2px rgba(10, 2, 22, 0.1), 0 0 2px rgba(10, 2, 22, 0.05);
  --depth-3: 0 10px 10px -2px rgba(10, 2, 22, 0.08),
    0 0 4px rgba(10, 2, 22, 0.03);
  --depth-4: 0 16px 16px -4px rgba(10, 2, 22, 0.08),
    0 0 8px rgba(10, 2, 22, 0.03);
  --depth-5: 0 20px 16px -4px rgba(10, 2, 22, 0.08),
    0 0 12px rgba(10, 2, 22, 0.03);
}
