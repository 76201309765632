@layer base {
  :root,
  :host {
    /**
     * @tokens font-family
     * @presenter FontFamily
     */

    --font-stack: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';

    /**
     * @tokens font-weight
     * @presenter FontWeight
     */

    --font-weight-light: 350;
    --font-weight-normal: 400;
    --font-weight-heavy: 600;

    /**
     * @tokens font-size
     * @presenter FontSize
     */

    --type-0-font-size: calc(var(--base-grid-size) * 2.5);
    --type-1-font-size: calc(var(--base-grid-size) * 3);
    --type-2-font-size: calc(var(--base-grid-size) * 3.5);
    --type-3-font-size: calc(var(--base-grid-size) * 4);
    --type-4-font-size: calc(var(--base-grid-size) * 5);
    --type-5-font-size: calc(var(--base-grid-size) * 6);
    --type-6-font-size: calc(var(--base-grid-size) * 8);
    --type-7-font-size: calc(var(--base-grid-size) * 10);
    --type-8-font-size: calc(var(--base-grid-size) * 12);

    /**
     * @tokens line-height
     * @presenter LineHeight
     */

    --type-0-line-height-0: calc(var(--base-grid-size) * 3);
    --type-0-line-height-1: calc(var(--base-grid-size) * 4);
    --type-0-line-height-2: calc(var(--base-grid-size) * 5);

    --type-1-line-height-0: calc(var(--base-grid-size) * 3);
    --type-1-line-height-1: calc(var(--base-grid-size) * 4);
    --type-1-line-height-2: calc(var(--base-grid-size) * 5);

    --type-2-line-height-0: calc(var(--base-grid-size) * 4);
    --type-2-line-height-1: calc(var(--base-grid-size) * 5);
    --type-2-line-height-2: calc(var(--base-grid-size) * 6);

    --type-3-line-height-0: calc(var(--base-grid-size) * 4);
    --type-3-line-height-1: calc(var(--base-grid-size) * 5);
    --type-3-line-height-2: calc(var(--base-grid-size) * 6);

    --type-4-line-height-0: calc(var(--base-grid-size) * 5);
    --type-4-line-height-1: calc(var(--base-grid-size) * 6);
    --type-4-line-height-2: calc(var(--base-grid-size) * 8);

    --type-5-line-height-0: calc(var(--base-grid-size) * 6);
    --type-5-line-height-1: calc(var(--base-grid-size) * 7);
    --type-5-line-height-2: calc(var(--base-grid-size) * 9);

    --type-6-line-height-0: calc(var(--base-grid-size) * 8);
    --type-6-line-height-1: calc(var(--base-grid-size) * 10);
    --type-6-line-height-2: calc(var(--base-grid-size) * 12);

    --type-7-line-height-0: calc(var(--base-grid-size) * 10);
    --type-7-line-height-1: calc(var(--base-grid-size) * 12);
    --type-7-line-height-2: calc(var(--base-grid-size) * 14);

    --type-8-line-height-0: calc(var(--base-grid-size) * 12);
    --type-8-line-height-1: calc(var(--base-grid-size) * 14);
    --type-8-line-height-2: calc(var(--base-grid-size) * 16);
  }
}

@layer semantic {
  :root,
  :host {
    /**
     * @tokens semantic-type
     * @presenter FontFamily
     */

    --title-01: var(--font-weight-heavy) var(--type-1-font-size) /
      var(--type-1-line-height-1) var(--font-stack);
    --title-02: var(--font-weight-heavy) var(--type-2-font-size) /
      var(--type-2-line-height-0) var(--font-stack);
    --title-03: var(--font-weight-normal) var(--type-3-font-size) /
      var(--type-3-line-height-1) var(--font-stack);
    --title-04: var(--font-weight-normal) var(--type-4-font-size) /
      var(--type-4-line-height-1) var(--font-stack);
    --title-05: var(--font-weight-normal) var(--type-5-font-size) /
      var(--type-5-line-height-1) var(--font-stack);
    --title-06: var(--font-weight-normal) var(--type-6-font-size) /
      var(--type-6-line-height-1) var(--font-stack);

    --body-small: var(--font-weight-normal) var(--type-1-font-size) /
      var(--type-1-line-height-2) var(--font-stack);
    --body-medium: var(--font-weight-normal) var(--type-2-font-size) /
      var(--type-2-line-height-2) var(--font-stack);
    --body-large: var(--font-weight-normal) var(--type-3-font-size) /
      var(--type-3-line-height-2) var(--font-stack);

    --input-large: var(--font-weight-normal) var(--type-3-font-size) /
      var(--type-3-line-height-0) var(--font-stack);
    --input-medium: var(--font-weight-normal) var(--type-2-font-size) /
      var(--type-2-line-height-0) var(--font-stack);
    --input-small: var(--font-weight-normal) var(--type-1-font-size) /
      var(--type-1-line-height-0) var(--font-stack);

    --overline: var(--font-weight-normal) var(--type-1-font-size) /
      var(--type-1-line-height-0) var(--font-stack);

    --stat-large: var(--font-weight-heavy) var(--type-7-font-size) /
      var(--type-7-line-height-0) var(--font-stack);
    --stat-medium: var(--font-weight-heavy) var(--type-6-font-size) /
      var(--type-6-line-height-0) var(--font-stack);
    --stat-small: var(--font-weight-heavy) var(--type-5-font-size) /
      var(--type-5-line-height-0) var(--font-stack);
    --stat-xsmall: var(--font-weight-heavy) var(--type-3-font-size) /
      var(--type-3-line-height-0) var(--font-stack);
  }
}
